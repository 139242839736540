<template>
  <div>
    <h2 class="gavc-h3"> Remplissez les informations concernant votre repreneur </h2>

    <repreneurInformation
      v-on:invalidInformationChanged="isRepreneurInvalidValue = $event"
      v-on:isExistingRepreneurUsernameChanged="isExistingRepreneurUsername = $event"
    />
    <div class='row mb-4'>
      <div class="col-12 text-right">
        <button
          class="mt-2 gavc-btn gavc-btn--bone"
          @click="previousStep">
            Précédent
        </button>
        <button
          class="mt-2 ml-2 gavc-btn"
          @click="nextStep"
          :disabled="isRepreneurInvalidValue || isExistingRepreneurUsername">
            Suivant
        </button>
      </div>
    </div>
  </div>
</template>

<script>

const repreneurInformation = () => import('@/components/registration/cedant/repreneurRegistration/repreneurInformation')

export default {
  name: 'cedantRegistrationRepreneur',
  components: {
    repreneurInformation
  },
  data: function () {
    return {
      isRepreneurInvalidValue: true,
      isExistingRepreneurUsername: false
    }
  },
  mounted () {
    this.scrollToTop()
  },
  methods: {
    nextStep () {
      this.$emit('nextStep')
    },
    previousStep () {
      this.$emit('previousStep')
    }
  }
}
</script>
